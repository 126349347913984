<template>
  <div class="payments">
    <base-loading :waitFlg="waitFlg" />
    <div class="wrap">
      <div class="area page-title">
        会計
      </div>
      <div class="area search">
        <search-area
          ref="search-area"
          :textBoxLabel="'検索単語'"
          :textValue="searchWord"
          :placeholder="'飼主ID、飼主名、患者ID、患者名'"
          :periodFlg="true"
          :periodLabel="'診療日期間'"
          :defaultStartDate="defaultStartDate"
          :defaultEndDate="defaultEndDate"
          :searchButtonFlg="true"
          :waitFlg="waitFlg"
          @input="inputSearchWord"
          @input-start-date="inputDate($event, 'start')"
          @input-end-date="inputDate($event, 'end')"
          @clear="clearDate"
          @search="resetAndGetData"
        />
      </div>
      <div class="area checkboxes">
        <base-check-box
          class="checkbox"
          :isChecked="showCompleteFlg"
          :labelText="'会計済'"
          :disabled="waitFlg"
          v-model="showCompleteFlg"
        />
        <base-check-box
          class="checkbox"
          :isChecked="showUnpaidFlg"
          :labelText="'未会計'"
          :disabled="waitFlg"
          v-model="showUnpaidFlg"
        />
        <base-check-box
          class="checkbox"
          :isChecked="showRedoFlg"
          :labelText="'再会計'"
          :disabled="waitFlg"
          v-model="showRedoFlg"
        />
        <base-check-box
          class="checkbox"
          :isChecked="showIncompleteFlg"
          :labelText="'未収金'"
          :disabled="waitFlg"
          v-model="showIncompleteFlg"
        />
      </div>
      <div class="area search-order">
        <div class="order-result">検索結果：{{ hitAllDataCounts }}件</div>
        <div class="order-setting">
          <div class="label">並び替え：</div>
          <base-select-box
            class="select-box"
            :selectData="sortTypes"
            v-model="sortType"
          />
          <base-select-box
            class="select-box"
            :selectData="orderTypes"
            v-model="orderType"
          />
        </div>
      </div>
      <div class="area list">
        <payment-list-table
          :payments="searchedPayments"
          :allFlg="true"
          :lastIndex="searchedPayments.length - 1"
          :initialDisplayFlg="initialDisplayFlg"
          :waitFlg="waitFlg"
          @go-to-show-payment-page="goToShowPaymentPage"
          @go-to-new-payment-page="goToNewPaymentPage"
          @scroll-bottom="getNextData"
        >
        </payment-list-table>
      </div>
    </div>
    <announce-popup
      v-if="popupFlg"
      :type="type"
      :title="title"
      :buttons="buttons"
      @close="closePopup"
      >{{ popupMessage }}</announce-popup
    >
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import BaseSelectBox from '@/components/parts/atoms/BaseSelectBox'
import PaymentListTable from '@/components/parts/organisms/PaymentListTable'
import SearchArea from '@/components/parts/molecules/SearchArea'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import { mapGetters } from 'vuex'
import { makePaymentDetail } from '@/utils/price_calculation'
import moment from 'moment'

export default {
  name: 'Payments',

  components: {
    BaseCheckBox,
    BaseLoading,
    BaseSelectBox,
    PaymentListTable,
    SearchArea,
    AnnouncePopup
  },

  data() {
    return {
      searchedPayments: [],
      initialDisplayFlg: true,
      searchWord: '',
      showCompleteFlg: true,
      showIncompleteFlg: true,
      showRedoFlg: true,
      showUnpaidFlg: true,
      startDate: '',
      defaultStartDate: '',
      endDate: '',
      defaultEndDate: '',
      sortType: 0,
      sortTypes: [
        { id: 0, name: '診療日順' },
        { id: 1, name: '会計日順' }
      ],
      orderType: 0,
      orderTypes: [
        { id: 0, name: '降順' },
        { id: 1, name: '昇順' }
      ],
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      buttons: [],
      waitFlg: false,
      page: 0,
      isFull: true,
      hitAllDataCounts: 0
    }
  },

  computed: {
    ...mapGetters({
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById',
      medicalPayments: 'medicalPayments/getData',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getPaymentsByMedicalPaymentId: 'payments/getDataByMedicalPaymentId',
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId'
    })
  },

  watch: {
    sortType() {
      this.resetAndGetData()
    },
    orderType() {
      this.resetAndGetData()
    },
    showCompleteFlg() {
      this.resetAndGetData()
    },
    showUnpaidFlg() {
      this.resetAndGetData()
    },
    showRedoFlg() {
      this.resetAndGetData()
    },
    showIncompleteFlg() {
      this.resetAndGetData()
    }
  },

  created() {
    const currentMoment = moment()
    this.startDate = currentMoment.format('YYYYMMDD')
    this.defaultStartDate = currentMoment.toDate()
    this.endDate = currentMoment.format('YYYYMMDD')
    this.defaultEndDate = currentMoment.toDate()
    this.createInitialSearchedPayments()
    this.hitAllDataCounts = this.searchedPayments.length
  },

  methods: {
    filterByDate(medicalRecordDate, startDate, endDate) {
      return startDate <= medicalRecordDate && medicalRecordDate <= endDate
    },
    makeDisplayDatum(medicalPayment) {
      const {
        type,
        unpaidAmount,
        totalCash,
        totalCard,
        totalChange,
        latestPayDateTime,
        medicalTreatmentDay,
        medicalRecordId
      } = makePaymentDetail(
        medicalPayment,
        this.getMedicalPaymentHistory,
        this.getMedicalRecordByOriginalId,
        this.getPaymentsByMedicalPaymentId
      )
      return {
        ...medicalPayment,
        type,
        unpaidAmount,
        red: unpaidAmount !== 0,
        cash: totalCash || 0,
        card: totalCard || 0,
        change: totalChange || 0,
        payDateTime: latestPayDateTime,
        date: medicalTreatmentDay,
        latestMedicalRecordId: medicalRecordId,
        ...this.makeDisplayOwnerPatient(medicalPayment)
      }
    },
    makeDisplayOwnerPatient(medicalPayment) {
      const patient = this.getPatient(medicalPayment.patientId)
      const owner = this.getOwner(patient.ownerId)
      return {
        ownerShowId: owner.showId,
        ownerFullName: `${owner.lastName} ${owner.firstName}`,
        ownerFullNameKana: `${owner.lastNameKana} ${owner.firstNameKana}`,
        patientShowId: patient.showId,
        patientName: patient.name,
        patientNameKana: patient.nameKana,
        speciesName: this.getSpecies(patient.speciesId).name
      }
    },
    createInitialSearchedPayments() {
      this.searchedPayments = this.medicalPayments
        .filter(
          v =>
            v.middleCalculateFlg === 1 ||
            v.endHospitalizationFlg === 1 ||
            (v.endHospitalizationFlg === 0 &&
              v.startHospitalizationFlg === 0 &&
              v.inHospitalFlg === 0 &&
              v.middleCalculateFlg === 0)
        )
        .flatMap(medicalPayment => {
          const medicalRecord = this.getMedicalRecordByOriginalId(
            medicalPayment.medicalRecordOriginalId
          )
          if (
            this.filterByDate(medicalRecord.date, this.startDate, this.endDate)
          ) {
            return this.makeDisplayDatum(medicalPayment)
          } else {
            return []
          }
        })
        .sort((a, b) => {
          return b.latestMedicalRecordId - a.latestMedicalRecordId
        })
    },
    createSearchedPayments(displayPayments) {
      const lastKey = this.searchedPayments.length
      const addData = displayPayments.map((displayPayment, index) => {
        return {
          ...displayPayment,
          ...this.makeDisplayOwnerPatient(displayPayment),
          number: lastKey + index
        }
      })
      this.searchedPayments = this.searchedPayments.concat(addData)
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    inputSearchWord(text) {
      this.searchWord = text
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    async resetAndGetData() {
      this.searchedPayments = []
      this.page = 0
      this.isFull = false
      if (
        !this.showCompleteFlg &&
        !this.showUnpaidFlg &&
        !this.showRedoFlg &&
        !this.showIncompleteFlg
      ) {
        this.hitAllDataCounts = 0
      } else {
        await this.searchPayments()
      }
    },
    async getNextData() {
      if (!this.isFull && !this.waitFlg) await this.searchPayments()
    },
    async searchPayments() {
      if (this.initialDisplayFlg) this.initialDisplayFlg = false
      this.waitFlg = true
      const trimSearchText = this.searchWord.toLowerCase().replace(/\s+/g, '')
      const res = await this.$store.dispatch('payments/search', {
        trimSearchText: trimSearchText,
        period: { startDate: this.startDate, endDate: this.endDate },
        checkInfo: {
          showCompleteFlg: this.showCompleteFlg,
          showUnpaidFlg: this.showUnpaidFlg,
          showRedoFlg: this.showRedoFlg,
          showIncompleteFlg: this.showIncompleteFlg
        },
        page: this.page,
        sortType: this.sortType === 0 ? '診療日順' : '会計日順',
        orderType: this.orderType === 0 ? 'DESC' : 'ASC'
      })
      if (res.result === true) {
        this.page++
        this.hitAllDataCounts = res.hitAllDataCounts
        this.createSearchedPayments(res.displayPayments)
        if (
          this.searchedPayments.length < 100 ||
          res.displayPayments.length === 0
        ) {
          this.isFull = true
        }
        if (this.page >= 10 && this.searchedPayments.length >= 1000) {
          this.isFull = true
          this.popupFlg = true
          this.type = 'alert'
          this.title = '警告'
          this.buttons = ['閉じる']
          this.popupMessage = '会計の表示件数が上限の1000件に達しました。'
        }
      } else {
        this.popupFlg = true
        this.type = 'failure'
        this.title = '失敗'
        this.buttons = ['閉じる']
        this.popupMessage = '通信エラーが発生しました。'
      }
      this.waitFlg = false
    },
    goToShowPaymentPage(payment, index) {
      const medicalPaymentOriginalId = payment.originalId //←medicalPaymentのoriginalIdの値
      const latestMedicalPayment = this.getMedicalPaymentByOriginalId(
        medicalPaymentOriginalId
      )
      if (latestMedicalPayment.delFlg === 1) {
        this.openNotMovePopup()
      } else {
        const latestDisplayPayment = this.makeDisplayDatum(latestMedicalPayment)
        if (!this.isEqualData(payment, latestDisplayPayment)) {
          this.openUpdatePopup()
          this.$set(this.searchedPayments, index, latestDisplayPayment)
        } else {
          const patient = this.getPatient(latestMedicalPayment.patientId)
          this.$router.push({
            path: `/main/karte/owners/${patient.ownerId}/patients/${patient.id}/payments/${medicalPaymentOriginalId}/show`
          })
        }
      }
    },
    goToNewPaymentPage(payment, index) {
      const medicalPaymentOriginalId = payment.originalId //←medicalPaymentのoriginalIdの値
      const latestMedicalPayment = this.getMedicalPaymentByOriginalId(
        medicalPaymentOriginalId
      )
      if (latestMedicalPayment.delFlg === 1) {
        this.openNotMovePopup()
      } else {
        const latestDisplayPayment = this.makeDisplayDatum(latestMedicalPayment)
        if (!this.isEqualData(payment, latestDisplayPayment)) {
          this.openUpdatePopup()
          this.$set(this.searchedPayments, index, latestDisplayPayment)
        } else {
          const patient = this.getPatient(latestMedicalPayment.patientId)
          this.$router.push({
            path: `/main/karte/owners/${patient.ownerId}/patients/${patient.id}/payments/${medicalPaymentOriginalId}/new`
          })
        }
      }
    },
    openNotMovePopup() {
      this.popupFlg = true
      this.type = 'failure'
      this.title = '失敗'
      this.buttons = ['閉じる']
      this.popupMessage =
        'クリックした会計の診療明細は既に削除されているため、\n会計画面に移動できません。'
    },
    isEqualData(payment, latestPayment) {
      return (
        payment.type === latestPayment.type &&
        payment.date === latestPayment.date &&
        payment.payDateTime === latestPayment.payDateTime &&
        payment.burdenAmount === latestPayment.burdenAmount &&
        payment.unpaidAmount === latestPayment.unpaidAmount &&
        payment.cash === latestPayment.cash &&
        payment.card === latestPayment.card
      )
    },
    openUpdatePopup() {
      this.popupFlg = true
      this.type = 'alert'
      this.title = '注意'
      this.buttons = ['閉じる']
      this.popupMessage =
        'クリックした会計データが更新されているため、再表示します。'
    },
    closePopup() {
      this.popupFlg = false
      this.type = ''
      this.title = ''
      this.buttons = []
      this.popupMessage = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.payments {
  width: 100%;
  overflow: auto;
  > .wrap {
    padding: 24px 48px;
    min-width: 1380px;
    text-align: left;
    > .page-title {
      height: 33px;
      font-weight: bold;
      font-size: 20px;
      padding: 20px 0;
      margin-bottom: 30px;
      border-bottom: 1px solid #{$light-grey};
    }
    > .search {
      min-width: 100%;
      margin-top: 40px;
    }
    > .search-order {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      > .order-setting {
        display: flex;
        align-items: center;
        > .select-box {
          margin-left: 10px;
        }
      }
    }
    > .checkboxes {
      margin-top: 40px;
      display: flex;
      align-items: center;
      > .checkbox ~ .checkbox {
        padding-left: 30px;
      }
      > .red-circled-number {
        margin-left: 5px;
        width: 23px;
        height: 23px;
        text-align: center;
        line-height: 23px;
        border-radius: 50%;
        color: #{$white};
        font-size: 13px;
        background-color: #{$tomato};
        cursor: pointer;
        @include hover();
      }
    }
    > .list {
      margin-top: 15px;
      ::v-deep .table-body {
        max-height: 630px;
      }
    }
  }
}
</style>
